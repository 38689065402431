import styled from "styled-components";
import "../i18n";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "@mdi/react";
import { mdiChartLine, mdiAccountMultiple } from "@mdi/js";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import i18next, { t, changeLanguage } from "i18next";
import OverallStandings from "../types/OverallStandings";
import * as GoalsRestClient from "../services/GoalsRestClient";
import Ranking from "../types/Ranking";
import RankingEntry from "../types/RankingEntry";
import { useSearchParams } from "react-router-dom";
import logo from "../assets/img/schenkerlogo.png";
import bottomLogo from "../assets/img/logo-bottom.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";

export const SCHENKER_GREEN = "#167B83";

const regionsMapping = require("../data/regionsMapping.json");
const countriesMapping = require("../data/countriesMapping.json");
const countryHeadcounts = require("../data/countryHeadcounts.json");

SwiperCore.use([Autoplay]);

interface MicrositeProps {}

const Microsite = (props: MicrositeProps) => {
  let [searchParams] = useSearchParams();

  const [overallStandings, setOverallStandings] = useState<OverallStandings>();
  const [quarterTimePassed, setQuarterTimePassed] = useState<number>(0);

  // eslint-disable-next-line
  const [regionsRankingListItemsMonthly, setRegionsRankingListItemsMonthly] =
    useState<Array<object>>([]);
  const [
    regionsRankingListItemsQuarterly,
    setRegionsRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);

  const [
    countriesSmallRankingListItemsMonthly,
    setCountriesSmallRankingListItemsMonthly,
  ] = useState<Array<object>>([]);

  const [
    countriesMediumRankingListItemsMonthly,
    setCountriesMediumRankingListItemsMonthly,
  ] = useState<Array<object>>([]);

  const [
    countriesLargeRankingListItemsMonthly,
    setCountriesLargeRankingListItemsMonthly,
  ] = useState<Array<object>>([]);

  const [teamsRankingListItemsMonthly, setTeamsRankingListItemsMonthly] =
    useState<Array<object>>([]);

  const loadOverallStandings = useCallback(async () => {
    let overallStandings: OverallStandings =
      await GoalsRestClient.getOverallStandings(i18next.language);
    setOverallStandings(overallStandings);
    loadQuarterTimePassed(overallStandings);
  }, []);

  const loadQuarterTimePassed = (overallStandings: OverallStandings) => {
    let today = new Date();

    if (
      !overallStandings?.startTime ||
      today.getTime() < overallStandings.startTime
    ) {
      setQuarterTimePassed(0);
      return;
    }

    let quarter = Math.floor((today.getMonth() + 3) / 3);
    let startingMonth =
      quarter === 4 ? 9 : quarter === 3 ? 6 : quarter === 2 ? 3 : 0;
    let quarterStartDate = new Date(
      today.getFullYear(),
      startingMonth,
      1,
      0
    ).getTime();
    let quarterEndDate =
      new Date(today.getFullYear(), startingMonth + 3, 1, 0).getTime() - 1000;
    let quarterLength = quarterEndDate - quarterStartDate;
    let timeSinceQuarterStartDate = today.getTime() - quarterStartDate;
    let quarterTimePassed = timeSinceQuarterStartDate / quarterLength;
    let quarterTimePassedPercent = Math.round(quarterTimePassed * 100);
    setQuarterTimePassed(quarterTimePassedPercent);
  };

  const setRankingList = useCallback(
    (
      sourceArray: Array<RankingEntry>,
      setFunction: (
        value: ((prevState: Array<object>) => Array<object>) | Array<object>
      ) => void,
      highlightIds: string[],
      metric: string,
      scoreWidth: number
    ): void => {
      const itemsList = [];
      for (let i = 0; i < sourceArray.length && i < 10; i++) {
        itemsList.push(
          getRankingListItem(sourceArray[i], highlightIds, metric, scoreWidth)
        );
        if (i < sourceArray.length) {
          itemsList.push(
            <HorizontalRule key={"horizontalRule" + sourceArray[i].id} />
          );
        }
      }
      for (let i = 0; i < highlightIds.length; i++) {
        let pos =
          sourceArray.find((e) => e.id === highlightIds[i])?.position || 0;
        if (pos > 10) {
          itemsList.push(
            getRankingListItem(
              sourceArray[pos - 1],
              highlightIds,
              metric,
              scoreWidth
            )
          );
        }
      }
      setFunction(itemsList);
    },
    []
  );

  let country = searchParams.get("country") || "";
  let region = searchParams.get("region") || "";

  const getLocationText = () => {
    if (country) {
      return countriesMapping[country];
    }
    if (region) {
      return regionsMapping[region];
    }
    return "Global";
  };

  const getQuarterText = () => {
    return `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
  };

  const getSizeOfCountryCategory = (country: string) => {
    const countryHeadcount = countryHeadcounts[countriesMapping[country]];
    if (countryHeadcount < 500) {
      return 1;
    }
    if (countryHeadcount > 1499) {
      return 3;
    }
    return 2;
  };

  const getRankingListItem = (
    sourceElement: RankingEntry,
    highlightIds: string[],
    metric: string,
    scoreWidth: number
  ) => {
    const isHighlighted =
      highlightIds && highlightIds.includes(sourceElement.id);
    return (
      <RankingListItem
        key={"rankingListItem" + sourceElement.id}
        backgroundColor={isHighlighted ? "#BBBBBB" : "#FFFFFF"}
      >
        <TextContainer width={15}>
          <Text
            fontSize={21}
            fontColor={"#000000"}
            fontWeight={isHighlighted ? "bold" : "normal"}
          >
            {sourceElement.position}.
          </Text>
        </TextContainer>
        <TextContainer width={85 - scoreWidth} textAlign={"left"}>
          <Text
            textAlign={"left"}
            fontSize={21}
            fontColor={"#000000"}
            fontWeight={isHighlighted ? "bold" : "normal"}
          >
            {sourceElement.name}
          </Text>
        </TextContainer>
        <TextContainer width={scoreWidth} textAlign={"right"}>
          <Text
            fontSize={21}
            fontColor={"#000000"}
            fontWeight={isHighlighted ? "bold" : "normal"}
            textAlign={"right"}
            paddingRight={15}
          >
            {Number(sourceElement.score).toFixed(2)} {metric}
          </Text>
        </TextContainer>
      </RankingListItem>
    );
  };

  const loadRankingListItems = useCallback(
    async (teamId: string | null, teamIds: string | null) => {
      let regionsRanking: Ranking = await GoalsRestClient.getRegionsRanking(
        teamId,
        teamIds
      );
      if (region) {
        regionsRanking.highlighted.push(region);
      }
      setRankingList(
        regionsRanking.monthly,
        setRegionsRankingListItemsMonthly,
        regionsRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        regionsRanking.quarterly,
        setRegionsRankingListItemsQuarterly,
        regionsRanking.highlighted,
        "%",
        20
      );
      let countriesRanking: Ranking = await GoalsRestClient.getCountriesRanking(
        teamId,
        teamIds
      );

      if (country) {
        countriesRanking.highlighted.push(country);
      }
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 1)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          }),
        setCountriesSmallRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 2)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          }),
        setCountriesMediumRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 3)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          }),
        setCountriesLargeRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      let teamsRanking: Ranking = await GoalsRestClient.getTeamsRanking(
        teamId,
        teamIds,
        region,
        country
      );
      setRankingList(
        teamsRanking.monthly,
        setTeamsRankingListItemsMonthly,
        teamsRanking.highlighted,
        "km / person",
        45
      );
    },
    [
      setRankingList,
      setRegionsRankingListItemsMonthly,
      setRegionsRankingListItemsQuarterly,
      setCountriesSmallRankingListItemsMonthly,
      setCountriesMediumRankingListItemsMonthly,
      setCountriesLargeRankingListItemsMonthly,
      setTeamsRankingListItemsMonthly,
      country,
      region,
    ]
  );

  useEffect(() => {
    let locale = searchParams.get("language") || "en";
    changeLanguage(locale.substring(0, 2));
    loadRankingListItems("", "");
    setInterval(() => {
      loadRankingListItems("", "");
    }, 50000);
  }, [searchParams, loadRankingListItems]);

  useEffect(() => {
    loadOverallStandings();
    setInterval(() => {
      loadOverallStandings();
    }, 50000);
  }, [loadOverallStandings]);

  return (
    <Container>
      <BackgroundHeader
        style={{ position: "absolute" }}
        id={"nav"}
        gridArea={"nav"}
      />
      <BackgroundBottom />
      <HeaderText>Leading The Way Company Challenge</HeaderText>
      <Swiper
        style={{ width: "100%" }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <VerticalGridContainer>
            <CardContainer>
              <TitleContainer>
                <Text
                  fontSize={27}
                  fontColor={"#000000"}
                  fontWeight={"bold"}
                  align={"left"}
                >
                  {t("FULL_YEAR_RESULTS_HEADER")}
                </Text>
                <MonthlyQuarterlyContainer>
                  <AbsoluteText
                    fontSize={18}
                    fontColor={"#000000"}
                    fontWeight={"normal"}
                  >
                    {t("YTD")}
                  </AbsoluteText>
                </MonthlyQuarterlyContainer>
              </TitleContainer>
              <CardContainerHeader>
                <CardContainerHeaderProgress
                  width={
                    overallStandings?.longGoal?.current &&
                    overallStandings?.longGoal?.goal
                      ? (overallStandings?.longGoal?.current /
                          overallStandings.longGoal.goal) *
                        100
                      : 0
                  }
                />
                <Text
                  align={"left"}
                  fontSize={15}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {t("OVERALL_GOAL_ACHIEVED", {
                    value: overallStandings?.longGoal?.current
                      ? Math.round(
                          (overallStandings?.longGoal?.current /
                            overallStandings?.longGoal?.goal) *
                            100
                        )
                      : 0,
                  })}
                </Text>
                <Text
                  textAlign={"right"}
                  fontSize={15}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {overallStandings?.longGoal?.goal
                    .toString()
                    .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
                  {overallStandings?.metricName || t("Kilometers")}
                </Text>
              </CardContainerHeader>
              <HorizontalRule />
              <VerticalContainer justifyContent={"center"}>
                <HorizontalContainer
                  height={105}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Icon
                    path={mdiChartLine}
                    size={1.65}
                    horizontal
                    vertical
                    rotate={180}
                    color="black"
                    style={{
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                    }}
                  />
                  <HorizontalTextContainer>
                    <Text
                      alignSelf={"flex-end"}
                      align={"center"}
                      fontSize={30}
                      fontWeight={"bold"}
                      fontColor={"#000000"}
                    >
                      {Math.round(overallStandings?.current || 0)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
                    </Text>
                    <Text
                      paddingLeft={2}
                      alignSelf={"flex-end"}
                      align={"center"}
                      fontSize={18}
                      fontWeight={"normal"}
                      fontColor={"#000000"}
                    >
                      {" "}
                      {overallStandings?.metricName || t("Kilometers")}
                    </Text>
                  </HorizontalTextContainer>
                </HorizontalContainer>
              </VerticalContainer>
              <HorizontalRule />
              <HorizontalContainer height={105} alignItems={"center"}>
                <Icon
                  path={mdiAccountMultiple}
                  size={1.65}
                  horizontal
                  vertical
                  rotate={180}
                  color="black"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "8px",
                    paddingTop: 4,
                  }}
                />
                <HorizontalTextContainer>
                  <Text
                    textAlign={"left"}
                    alignSelf={"flex-end"}
                    align={"center"}
                    fontSize={30}
                    fontWeight={"bold"}
                    fontColor={"#000000"}
                  >
                    {overallStandings?.teamCount}
                  </Text>
                  <Text
                    paddingLeft={2}
                    alignSelf={"flex-end"}
                    fontSize={18}
                    fontWeight={"normal"}
                    fontColor={"#000000"}
                  >
                    {" "}
                    {t("TEAMS")}
                  </Text>
                </HorizontalTextContainer>
              </HorizontalContainer>
            </CardContainer>
          </VerticalGridContainer>
        </SwiperSlide>
        <SwiperSlide>
          <VerticalGridContainer>
            <CardContainer
              style={{
                justifyContent: "center",
                alignItems: "center",
                minHeight: 600,
              }}
            >
              <TitleContainer>
                <Text
                  fontSize={27}
                  fontColor={"#000000"}
                  fontWeight={"bold"}
                  align={"left"}
                >
                  {t("QUARTERLY_RESULTS_HEADER")}
                </Text>
                <MonthlyQuarterlyContainer>
                  <AbsoluteText
                    fontSize={18}
                    fontColor={"#000000"}
                    fontWeight={"normal"}
                  >
                    {t("QUARTER")}
                  </AbsoluteText>
                </MonthlyQuarterlyContainer>
              </TitleContainer>
              <Text
                fontSize={27}
                fontColor={"#aaaaaa"}
                fontWeight={"bold"}
                style={{ position: "absolute", left: 10, top: 70 }}
              >
                {getQuarterText()}
              </Text>
              <HorizontalContainer
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={30}
                marginBottom={30}
              >
                <VerticalContainer
                  justifyContent={"center"}
                  maxHeight={240}
                  maxWidth={240}
                  marginRight={45}
                >
                  <CircularProgressbarWithChildren
                    strokeWidth={7}
                    styles={buildStyles({
                      pathColor: SCHENKER_GREEN,
                      trailColor: "#e9e9e9",
                      strokeLinecap: "butt",
                    })}
                    value={
                      overallStandings?.quarterlyGoal
                        ? (overallStandings.quarterlyGoal.current /
                            overallStandings.quarterlyGoal.goal) *
                          100
                        : 0
                    }
                  >
                    <RelativeContainer height={90} width={90}>
                      <CircularProgressbarWithChildren
                        strokeWidth={7}
                        styles={buildStyles({
                          pathColor: "#aaaaaa",
                          trailColor: "#e9e9e9",
                          strokeLinecap: "butt",
                        })}
                        value={quarterTimePassed}
                      >
                        <Text
                          align={"center"}
                          fontSize={21}
                          fontWeight={"normal"}
                          fontColor={SCHENKER_GREEN}
                          paddingBottom={4}
                        >
                          {t("DONE")}
                        </Text>
                        <Text
                          align={"center"}
                          fontSize={45}
                          fontWeight={"bold"}
                          fontColor={SCHENKER_GREEN}
                          paddingBottom={6}
                        >
                          {Math.round(
                            (overallStandings?.quarterlyGoal
                              ? overallStandings.quarterlyGoal.current /
                                overallStandings.quarterlyGoal.goal
                              : 0) * 100
                          )}
                          %
                        </Text>
                        <Text
                          align={"center"}
                          fontSize={15}
                          fontWeight={"normal"}
                          fontColor={"#000000"}
                        >
                          {t("TIME_LEFT")}
                        </Text>
                        <Text
                          align={"center"}
                          fontSize={18}
                          fontWeight={"normal"}
                          fontColor={"#000000"}
                        >
                          {100 - quarterTimePassed}%
                        </Text>
                      </CircularProgressbarWithChildren>
                    </RelativeContainer>
                  </CircularProgressbarWithChildren>
                </VerticalContainer>
                <VerticalContainer minWidth={90} paddingLeft={6}>
                  <VerticalContainer
                    justifyContent={"flex-start"}
                    alignItems={"flex-end"}
                    marginRight={12}
                  ></VerticalContainer>
                  <VerticalContainer alignItems={"flex-start"}>
                    <Text
                      fontSize={18}
                      fontWeight={"normal"}
                      fontColor={"#000000"}
                    >
                      {t("OUR_QUARTERLY_GOAL")}
                    </Text>
                    <HorizontalTextContainer>
                      <Text
                        fontSize={30}
                        fontWeight={"bold"}
                        fontColor={"#000000"}
                      >
                        {overallStandings?.quarterlyGoal?.goal
                          ? overallStandings?.quarterlyGoal?.goal
                              .toString()
                              .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                          : 0}
                      </Text>
                      <Text
                        paddingLeft={6}
                        alignSelf={"flex-end"}
                        paddingBottom={9}
                        fontSize={18}
                        fontWeight={"normal"}
                        fontColor={"#000000"}
                      >
                        {" "}
                        {overallStandings?.metricName || t("Kilometers")}
                      </Text>
                    </HorizontalTextContainer>
                  </VerticalContainer>
                  <VerticalContainer marginTop={18} alignItems={"flex-start"}>
                    <Text
                      fontSize={18}
                      fontWeight={"normal"}
                      fontColor={"#000000"}
                    >
                      {t("CURRENT_RESULT")}
                    </Text>
                    <HorizontalTextContainer>
                      <Text
                        fontSize={30}
                        fontWeight={"bold"}
                        fontColor={"#000000"}
                      >
                        {Math.round(
                          overallStandings?.quarterlyGoal?.current
                            ? overallStandings?.quarterlyGoal?.current
                            : 0
                        )
                          .toString()
                          .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
                      </Text>
                      <Text
                        paddingLeft={6}
                        alignSelf={"flex-end"}
                        fontSize={18}
                        fontWeight={"normal"}
                        fontColor={"#000000"}
                      >
                        {" "}
                        {overallStandings?.metricName || t("Kilometers")}
                      </Text>
                    </HorizontalTextContainer>
                  </VerticalContainer>
                </VerticalContainer>
              </HorizontalContainer>
              <HorizontalRule />
              <VerticalContainer
                justifyContent={"space-evenly"}
                minHeight={195}
                style={{ width: "100%" }}
              >
                <Text
                  align={"center"}
                  fontSize={18}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {overallStandings?.donation?.totalContributionText ||
                    t("OUR_CURRENT_DONATION")}
                </Text>
                <Text
                  align={"center"}
                  fontSize={30}
                  fontWeight={"bold"}
                  fontColor={SCHENKER_GREEN}
                >
                  {overallStandings?.donation?.current
                    ? Math.round(Number(overallStandings?.donation?.current))
                        .toString()
                        .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                    : 0}{" "}
                  {overallStandings?.donation?.unitName}
                </Text>
                <HorizontalRule />
                <Text
                  align={"center"}
                  fontSize={18}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {overallStandings?.donation?.goalText ||
                    t("OUR_QUARTERLY_DONATION_GOAL")}
                </Text>
                <Text
                  align={"center"}
                  fontSize={30}
                  fontWeight={"bold"}
                  fontColor={SCHENKER_GREEN}
                >
                  {overallStandings?.donation?.goal
                    ? Math.round(Number(overallStandings?.donation?.goal))
                        .toString()
                        .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                    : 0}{" "}
                  {overallStandings?.donation?.unitName}
                </Text>
              </VerticalContainer>
            </CardContainer>
          </VerticalGridContainer>
        </SwiperSlide>
        <SwiperSlide>
          <VerticalGridContainer>
            <TableContainer>
              <TitleContainer>
                <Text
                  fontSize={27}
                  fontColor={"#000000"}
                  fontWeight={"bold"}
                  align={"left"}
                >
                  {t("REGIONS_ACHIEVEMENT_HEADER")}
                </Text>
                <MonthlyQuarterlyContainer>
                  <AbsoluteText
                    fontSize={18}
                    fontColor={"#000000"}
                    fontWeight={"normal"}
                  >
                    {t("QUARTER")}
                  </AbsoluteText>
                </MonthlyQuarterlyContainer>
              </TitleContainer>
              {regionsRankingListItemsQuarterly}
            </TableContainer>
          </VerticalGridContainer>
        </SwiperSlide>
        {!country || getSizeOfCountryCategory(country) === 1 ? (
          <SwiperSlide>
            <VerticalGridContainer>
              <TableContainer>
                <TitleContainer>
                  <Text
                    fontSize={27}
                    fontColor={"#000000"}
                    fontWeight={"bold"}
                    align={"left"}
                  >
                    {t("COUNTRIES_ACHIEVEMENT_HEADER") + t("COUNTRIES_SIZE_SMALL")}
                  </Text>
                  <MonthlyQuarterlyContainer>
                    <AbsoluteText
                      fontSize={18}
                      fontColor={"#000000"}
                      fontWeight={"normal"}
                    >
                      {t("MONTH")}
                    </AbsoluteText>
                  </MonthlyQuarterlyContainer>
                </TitleContainer>
                {countriesSmallRankingListItemsMonthly}
              </TableContainer>
            </VerticalGridContainer>
          </SwiperSlide>
        ) : null}
        {!country || getSizeOfCountryCategory(country) === 2 ? (
          <SwiperSlide>
            <VerticalGridContainer>
              <TableContainer>
                <TitleContainer>
                  <Text
                    fontSize={27}
                    fontColor={"#000000"}
                    fontWeight={"bold"}
                    align={"left"}
                  >
                    {t("COUNTRIES_ACHIEVEMENT_HEADER") + t("COUNTRIES_SIZE_MEDIUM")}
                  </Text>
                  <MonthlyQuarterlyContainer>
                    <AbsoluteText
                      fontSize={18}
                      fontColor={"#000000"}
                      fontWeight={"normal"}
                    >
                      {t("MONTH")}
                    </AbsoluteText>
                  </MonthlyQuarterlyContainer>
                </TitleContainer>
                {countriesMediumRankingListItemsMonthly}
              </TableContainer>
            </VerticalGridContainer>
          </SwiperSlide>
        ) : null}
        {!country || getSizeOfCountryCategory(country) === 3 ? (
          <SwiperSlide>
            <VerticalGridContainer>
              <TableContainer>
                <TitleContainer>
                  <Text
                    fontSize={27}
                    fontColor={"#000000"}
                    fontWeight={"bold"}
                    align={"left"}
                  >
                    {t("COUNTRIES_ACHIEVEMENT_HEADER") + t("COUNTRIES_SIZE_LARGE")}
                  </Text>
                  <MonthlyQuarterlyContainer>
                    <AbsoluteText
                      fontSize={18}
                      fontColor={"#000000"}
                      fontWeight={"normal"}
                    >
                      {t("MONTH")}
                    </AbsoluteText>
                  </MonthlyQuarterlyContainer>
                </TitleContainer>
                {countriesLargeRankingListItemsMonthly}
              </TableContainer>
            </VerticalGridContainer>
          </SwiperSlide>
        ) : null}
        <SwiperSlide>
          <VerticalGridContainer>
            <TableContainer>
              <TitleContainer>
                <Text
                  fontSize={27}
                  fontColor={"#000000"}
                  fontWeight={"bold"}
                  align={"left"}
                >
                  {t("TOP_TEAMS_MONTHLY_HEADER", {
                    location: getLocationText(),
                  })}
                </Text>
                <MonthlyQuarterlyContainer>
                  <AbsoluteText
                    fontSize={18}
                    fontColor={"#000000"}
                    fontWeight={"normal"}
                  >
                    {t("MONTH")}
                  </AbsoluteText>
                </MonthlyQuarterlyContainer>
              </TitleContainer>
              {teamsRankingListItemsMonthly}
            </TableContainer>
          </VerticalGridContainer>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

type TextContainerProps = {
  width: number;
  backgroundColor?: string;
  textAlign?: string;
};

type ContainerProps = {
  height?: number;
  width?: number;
};

type RelativeContainerProps = {
  height?: number;
  width?: number;
};

type CardContainerProps = {};

type TableContainerProps = {};

type CardContainerHeaderProgressProps = {
  width: number;
};

type HorizontalContainerProps = {
  height?: number;
  width?: number;
  justifyContent?: string;
  alignItems?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  marginTop?: number;
  marginBottom?: number;
};

type VerticalContainerProps = {
  height?: number;
  width?: number;
  minHeight?: number;
  maxHeight?: number;
  maxWidth?: number;
  flex?: number;
  minWidth?: number;
  justifyContent?: string;
  alignItems?: string;
  borderRight?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  margin?: number;
  marginRight?: number;
  marginTop?: number;
  boxShadow?: boolean;
};

type HorizontalRuleProps = {
  width?: number;
};

type TextProps = {
  fontSize?: number;
  fontColor?: string;
  fontWeight?: string;
  lineHeight?: number;
  align?: string;
  backgroundColor?: string;
  alignSelf?: string;
  textAlign?: string;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

type VerticalGridContainerProps = {
  backgroundColor?: string;
};

type BackgroundHeaderProps = {
  gridArea: string;
};

type RankingListItemProps = {
  padding?: number;
  justifyContent?: string;
  backgroundColor?: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${SCHENKER_GREEN};
`;

const HeaderText = styled.h1`
  position: absolute;
  top: 10px;
  margin: 0;
  margin-left: 10px;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  font-family: "Ubuntu";
`;

const BackgroundHeader = styled.div<BackgroundHeaderProps>`
  background-color: ${SCHENKER_GREEN};
  min-height: 100vh;
  width: 100%;
  display: flex;
  background-image: url(${logo});
  background-size: 250px;
  background-repeat: no-repeat;
  background-position-y: 10px;
  background-position-x: calc(100% - 10px);
`;

const BackgroundBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 180px;
  width: 380px;
  background-image: url(${bottomLogo});
  background-size: 380px;
  background-repeat: no-repeat;
`;

const RelativeContainer = styled.div<RelativeContainerProps>`
  position: relative;
  margin: 8px;
  width: ${(props) => (props.width ? props.width : "10")}%;
  height: ${(props) => (props.height ? props.height : "10")}%;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
  box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
`;

const TableContainer = styled.div<TableContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
`;

const CardContainerHeader = styled.div`
  height: 36px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 15px 15px 0px 0px;
`;

const CardContainerHeaderProgress = styled.div<CardContainerHeaderProgressProps>`
  height: 36px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: ${(props) => props.width}%;
  background-color: #eeeeee;
`;

const HorizontalTextContainer = styled.div`
  display: inline;
  padding-top: 5px;
`;

const Text = styled.span<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight};
  margin: 0px;
  text-align: ${(props) => props.align};
  background-color: ${(props) => props.backgroundColor};
  align-self: ${(props) => props.alignSelf};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  z-index: 2;
  font-family: "Ubuntu";
`;

const AbsoluteText = styled.p<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  font-family: "Ubuntu";
`;

const HorizontalContainer = styled.div<HorizontalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  padding-left: ${(props) => props.paddingLeft || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
`;

const VerticalContainer = styled.div<VerticalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  margin-left: ${(props) => props.paddingLeft || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin: ${(props) => props.margin}px;
  ${(props) =>
    props.minHeight ? "min-height: " + props.minHeight + "px;" : ""}
  ${(props) =>
    props.maxHeight ? "max-height: " + props.maxHeight + "px;" : ""}
  ${(props) => (props.maxWidth ? "max-width: " + props.maxWidth + "px;" : "")}
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
  ${(props) => (props.width ? "width: " + props.width + "px;" : "")}
`;

const VerticalGridContainer = styled.div<VerticalGridContainerProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  margin-left: 270px;
  margin-right: 270px;
`;

const HorizontalRule = styled.hr<HorizontalRuleProps>`
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-color: #f0f0f0;
  color: #f0f0f0;
  background-color: #f0f0f0;
  width: ${(props) => (props.width ? props.width + "%" : "100%")};
  height: 1px;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #dbdbdb;
  min-height: 10%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  padding: 16px;
  box-sizing: border-box;
`;

const MonthlyQuarterlyContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  z-index: 5;
  bottom: 0;
  right: 0;
`;

const RankingListItem = styled.div<RankingListItemProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${(props) => props.padding}px;
  justify-content: ${(props) => props.justifyContent};
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "white"};
`;

const TextContainer = styled.p<TextContainerProps>`
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.backgroundColor};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  padding-right: 8px;
`;

export default Microsite;
