import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: { translation: require('./translations/en.json') },
    de: { translation: require('./translations/de.json') },
    es: { translation: require('./translations/es.json') },
    fi: { translation: require('./translations/fi.json') },
    fr: { translation: require('./translations/fr.json') },
    it: { translation: require('./translations/it.json') },
    ko: { translation: require('./translations/ko.json') },
    ms: { translation: require('./translations/ms.json') },
    nl: { translation: require('./translations/nl.json') },
    no: { translation: require('./translations/no.json') },
    pl: { translation: require('./translations/pl.json') },
    pt: { translation: require('./translations/pt.json') },
    sk: { translation: require('./translations/sk.json') },
    sv: { translation: require('./translations/sv.json') },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
